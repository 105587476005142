import { CreateToastFnReturn } from '@chakra-ui/react';
import { batiment } from '@tsTypes/mySitesTypes';
import { sensor } from '@tsTypes/sensorType';
import Highcharts, { PointOptionsObject } from 'highcharts';

export function PresenceChart(
	sensor: sensor,
	batiment: batiment,
	mesures: [number, number][],
	index: [number, number][],
	plotbands: Highcharts.AxisPlotBandsOptions[],
	toast: CreateToastFnReturn = null,
	simpleMode: boolean = false
): Highcharts.Options {
	return {
		chart: {
			type: 'columne',
			marginLeft: 75,
			zooming: {
				type: 'x',
				mouseWheel: {
					enabled: true,
				},
			},
			/*events: {
                load: !simpleMode ? function () {
                    const chart = this;

                    // Bouton de réinitialisation du zoom
                    chart.renderer
                        .button("Reset zoom", 0, 0, function () {
                            chart.zoomOut();
                        })
                        .attr({ zIndex: 999 })
                        .align({ align: "right", y: 90, x: -100 })
                        .add();
                } : null,
            },*/
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 910,
					},
					chartOptions: {
						title: {
							text: 'Présence',
						},
					},
				},
			],
		},
		rangeSelector: {
			enabled: false,
			buttons: [],
		},
		navigator: {
			enabled: simpleMode ? false : true,
		},
		legend: {
			enabled: simpleMode ? false : true,
		},
		tooltip: {
			valueDecimals: 2,
			xDateFormat: '%A %d %B %Y %H:%M:%S',
		},
		title: {
			text: `Consommation instantanée et cumulée ${batiment.nom_bat} - ${sensor.type_energie}`,
			y: 20, // Déplace le titre plus haut que le début du graphique
			style: {
				fontSize: '20px',
			},
			margin: 40,
		},
		xAxis: {
			type: 'datetime',
			labels: {
				rotation: 0, // Désactive la rotation des étiquettes de l'axe X
				style: {
					fontSize: '10px', // Réduit la taille de police des étiquettes de l'axe X
				},
			},
			plotBands: plotbands,
		},
		yAxis: [
			{
				min: 0,
				max: 1.2,
				labels: {
					formatter: function (): string {
						if (this.value === 0) {
							return 'Personne';
						} else if (this.value === 1) {
							return 'Présence';
						} else {
							return '';
						}
					},
				},
				opposite: false,
			},
		],

		boost: {
			enabled: true,
		},

		series: [
			{
				type: 'column',
				name: 'mesures instantanées',
				yAxis: 0,
				data: mesures as (number | [string | Date, number] | PointOptionsObject)[],
				dataGrouping: {
					enabled: false,
				},
				zones: [
					{
						value: 0,
						color: 'red',
					},
					{
						value: 1,
						color: 'green',
					},
				],
				tooltip: {
					valueSuffix: `  ${sensor.unite}`,
				},
			},
		],
	};
}
